import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  checkIfEmailVerified,
  adminEmail,
  adminPassword,
} from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./Login.css";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSignIn = async () => {
    setError(null);
    setShowError(false);

    if (!email || !password) {
      setShowError(true);
      return;
    }

    try {
      setLoadingSignIn(true);

      // Check for admin credentials
      if (email === adminEmail && password === adminPassword) {
        await signInWithEmailAndPassword(email, password);
        navigate("/admin");
        return;
      }

      // For regular users
      await signInWithEmailAndPassword(email, password);
      const userVerified = await checkIfEmailVerified(email);

      if (!userVerified) {
        setError("Please verify your email before signing in.");
        navigate("/verify-email");
      } else {
        navigate("/bookpage");
      }
    } catch (error) {
      const errorMessage =
        error.message || "An error occurred while signing in.";
      setError("Enter valid credentials.");
    } finally {
      setLoadingSignIn(false);
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <h2>LOGIN</h2>
        <p>If you've created an account already, please enter your details.</p>
        {error && <p className="error">{error}</p>}
        <div className="input-group">
          <div className="input-container">
            <FontAwesomeIcon icon={faEnvelope} />
            <input
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className={showError && !email ? "input-error" : ""}
            />
          </div>
          {showError && !email && (
            <span className="red-error">Email is required.</span>
          )}

          <div className="input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              value={password}
              onChange={handlePasswordChange}
              className={showError && !password ? "input-error" : ""}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className="toggle-password"
            />
          </div>
          {showError && !password && (
            <span className="red-error">Password is required.</span>
          )}
        </div>

        <div className="reset-password-link">
          <p>
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
        </div>

        <div className="btn">
          <button
            onClick={handleSignIn}
            disabled={loadingSignIn}
            className="signin-button"
          >
            {loadingSignIn ? "Signing In..." : "Sign In"}
          </button>
        </div>

        <div className="register-link">
          <p>
            Don't have an account? <Link to="/registration">Register here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
