// src/Books.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Books.css';

const Books = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(
'https://www.googleapis.com/books/v1/volumes?q=subject:science&orderBy=relevance&maxResults=8&key=AIzaSyCmSq1TVDrDfG6DrbZakTEyqyueSSvqJ1E'
        );
        setBooks(response.data.items);
      } catch (error) {
        console.error("Error fetching the books: ", error);
      }
    };

    fetchBooks();
  }, []);

  return (
    <div className="books-container">
      {books.map(book => (
        <div key={book.id} className="book-card">
          <img src={book.volumeInfo.imageLinks?.thumbnail} alt={book.volumeInfo.title} />
          <h3>{book.volumeInfo.authors ? book.volumeInfo.authors.join(', ') : 'Unknown Author'}</h3>
        </div>
      ))}
    </div>
  );
};

export default Books;
