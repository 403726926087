import React, { useState } from "react";
import axios from "axios";
import "./BookSearch.css"; // Add your custom styles here
import Bookpage from "../Pages/Bookpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const BookSearch = () => {
  const [query, setQuery] = useState("");
  const [books, setBooks] = useState([]);
  const [message, setMessage] = useState("");
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (query.trim() === "") {
      setInputError(true);
      setErrorMessage("Please enter a search term.");
      return;
    }

    setInputError(false);
    setErrorMessage("");
    setLoading(true);

    try {
      // Fetch from Gutenberg API
      const gutenbergResponse = await axios.get(
        `https://gutendex.com/books?search=${query}`
      );
      if (gutenbergResponse.data.results.length > 0) {
        setBooks(gutenbergResponse.data.results);
        setMessage("");
      } else {
        // Fetch from Google Books API if not found in Gutenberg
        const api = process.env.REACT_APP_GOOGLE_API 
        const googleResponse = await axios.get(
`https://www.googleapis.com/books/v1/volumes?q=${query}&key=${api}`
        );
        if (googleResponse.data.items && googleResponse.data.items.length > 0) {
          setBooks(googleResponse.data.items);
          setMessage(
            "These book are not yet downloadable for now. Try some other ones. "
          );
        } else {
          setMessage("No results found.");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("An error occurred while searching. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="book-search-container">
      <div className="bookbg">
        <div className="search-bar">
          <div className="searchinputcon">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for books"
              className={`search-input ${inputError ? "error" : ""}`}
            />
            <span
              onClick={handleSearch}
              disabled={!query.trim()}
              className="search-button"
            >
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>
        </div>

      </div>
      {inputError && <p className="error-message">{errorMessage}</p>}
      {message && <p className="message">{message}</p>}

      {loading ? (
        <div className="spinner"></div> // Add your spinner CSS class here
      ) :          
      (
        <div className="books-container">

          {books.map((book, index) => (
            <div key={index} className="book-card">
              {book.formats && book.formats["image/jpeg"] && (
                <img
                  src={book.formats["image/jpeg"]}
                  alt={book.title}
                  className="book-image"
                />
              )}
              {book.volumeInfo &&
                book.volumeInfo.imageLinks &&
                book.volumeInfo.imageLinks.thumbnail && (
                  <img
                    src={book.volumeInfo.imageLinks.thumbnail}
                    alt={book.volumeInfo.title}
                    className="book-image"
                  />
                )}
              <h3>{book.title || book.volumeInfo.title}</h3>
              <p>
                {book.authors
                  ? book.authors.map((author) => author.name).join(", ")
                  : book.volumeInfo.authors.join(", ")}
              </p>
              <div className="book-button">
                {book.formats && book.formats["text/html"] && (
                  <a
                    href={book.formats["text/html"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="book-link"
                  >
                    Read Live
                  </a>
                )}
                {book.formats && book.formats["application/epub+zip"] && (
                  <a
                    href={book.formats["application/epub+zip"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="book-link"
                  >
                    Download EPUB
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <Bookpage />
    </div>
  );
};

export default BookSearch;
