import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import Notification from './Notification';

function Subscribe() {


    const [email, setEmail] = useState('');
  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');


    const handleSubscribe = async (e) => {
        e.preventDefault();
    
        if (!email) {
          setError('Please enter your email address');
          return;
        }
    
        const templateParams = {
          message: email
        };
    
        try {
          await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
          );
    
          setNotification('Subscription request sent successfully!');
          setEmail('');
          setError('');
        } catch (error) {
          setError('Failed to subscribe. Please try again later.');
          console.error('Error:', error);
        }
      };
    
      const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError('');
      };
    
      const closeNotification = () => {
        setNotification('');
      };
    

  return (
    <div>
      {notification && <Notification message={notification} onClose={closeNotification} />}
      <form onSubmit={handleSubscribe}>
                    <input
                      type="email"
                      placeholder="Email address..."
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <button type="submit">Subscribe</button>
                  </form>
    </div>
  )
}

export default Subscribe
