import React, { useState, useEffect } from 'react';
import schlogo from '../Images/Schlogo.png';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, signOutUser } from '../firebase'; // Import auth and sign out function
import GoogleFormToggle from './GoogleFormToggle';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const isLoggedIned = !!auth.currentUser;

  useEffect(() => {
    const setDocumentTitle = (pathname) => {
      const descriptions = {
        '/': 'Home - WOCLIF School of Health Technology',
        '/bookpage': 'Library - WOCLIF School of Health Technology',
        '/about': 'About Us - WOCLIF School of Health Technology',
        '/contact': 'Contact - WOCLIF School of Health Technology',
        '/account': 'Account - WOCLIF School of Health Technology',
        '/login': 'Login - WOCLIF School of Health Technology',
        '/registration': 'Registration - WOCLIF School of Health Technology',
      };

      document.title = descriptions[pathname] || 'WOCLIF School of Health Technology';
    };

    setDocumentTitle(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Listen for changes in authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClick = () => {
    toggleCollapse();
    closeMenu();
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      setIsLoggedIn(false);
      toggleCollapse();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsCollapsed(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLibraryClick = () => {
    
    if (isLoggedIned) {
      navigate('/bookpage');
    } else {
      navigate('/login');
    }
  };

  const handlecontactClick = () => {
    
    if (isLoggedIned) {
      navigate('/contact');
    } else {
      navigate('/login');
    }
  };

  const handleallClick = () => {
      closeMenu();
      handleLibraryClick();
  }


  const handleallClicks = () => {
    closeMenu();
    handlecontactClick();}
  return (
    <div className="Header-container">
      <div className="bg">
        <header>
          <div>
            <div className="schlogo">
              <a href="/">
                <img src={schlogo} alt="School logo" />
              </a>
            </div>

            <nav className={`side-menu ${isMenuOpen ? "open" : ""}`}>
              <ul>
                <li>
                  <Link to="/" onClick={closeMenu}>
                    Home
                  </Link>
                </li>
                <li>
                  <span className="account-header" onClick={handleallClick}>
                    Library
                  </span>
                </li>
                <li>
                  <Link to="/about" onClick={closeMenu}>
                    About
                  </Link>
                </li>
                <li>
                  <span className="account-header" onClick={handleallClicks}>
                    Contact
                  </span>
                </li>
                <li>
                  <span className="account-header" onClick={handleClick}>
                    Account
                  </span>
                </li>
              </ul>
            </nav>

            <nav className="main-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span className="account-header" onClick={handleLibraryClick}>
                    Library
                  </span>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <span className="account-header" onClick={handlecontactClick}>
                    Contact
                  </span>
                </li>
                <li>
                  <span className="account-header" onClick={toggleCollapse}>
                    Account
                  </span>
                </li>
              </ul>
            </nav>

            <div className="dropicon" onClick={toggleMenu}>
              <span>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
              </span>
            </div>
          </div>
        </header>
        {isCollapsed && (
          <div className="collapsible-container">
            {isLoggedIn ? (
              <div className="Account-detail">
                <p>
                  Name:{" "}
                  {auth.currentUser.displayName ||
                    auth.currentUser.email.split("@")[0]}
                </p>
                <p>Email: {auth.currentUser.email}</p>
                <p>Date: {new Date().toLocaleDateString()}</p>
                <button onClick={handleSignOut}>Sign Out</button>
              </div>
            ) : (
              <>
                <div>
                  <h2 className="getstarted">Get Started</h2>
                </div>
                <div className="collapsible-containers">
                  <Link
                    onClick={handleClick}
                    to="/login"
                    className="dropdown-link"
                  >
                    Login
                  </Link>
                  <Link
                    onClick={handleClick}
                    to="/registration"
                    className="dropdown-link"
                  >
                    Register
                  </Link>
                </div>
              </>
            )}
          </div>
        )}
        <div>
          <Outlet />
        </div>
      </div>
      <div className="googlecon">
        <GoogleFormToggle />
      </div>
    </div>
  );
}

export default Header;
