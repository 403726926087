import React, { useState, useEffect } from "react";
import {
  signUpWithEmailAndPassword,
  sendEmailVerification,
  checkInvitationCode,
  markCodeAsUsed,
  checkIfUserExists,
} from "../firebase"; // Update the import path as necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./Registration.css";

function Registration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleInvitationCodeChange = (e) => setInvitationCode(e.target.value);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Validate the invitation code
      const isValidCode = await checkInvitationCode(invitationCode);
      if (!isValidCode) {
        throw new Error("Invalid or already used invitation code.");
      }

      // Check if the user already exists
      const userExists = await checkIfUserExists(email);
      if (userExists) {
        throw new Error("User already exists with this email.");
      }

      // Create the user
      const user = await signUpWithEmailAndPassword(
        email,
        password,
        invitationCode
      );
      await markCodeAsUsed(invitationCode);

      setPopup(true);
      setEmail("");
      setPassword("");
      setInvitationCode("");
    } catch (error) {
      if (error.message.includes("auth/too-many-requests")) {
        setError("Too many requests. Please try again later.");
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (popup) {
      const timer = setTimeout(() => {
        setPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [popup]);

  return (
    <div className="registration">
      <div className="registration-container">
        <h2>Sign Up</h2>
        <p>You can not register without an invitation code.</p>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="toggle-password"
                onClick={toggleShowPassword}
              />
            </div>
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} />
              <input
                type="text"
                placeholder="Enter your invitation code"
                value={invitationCode}
                onChange={handleInvitationCodeChange}
                required
              />
            </div>
          </div>
          <button type="submit" className="signup-button" disabled={loading}>
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </form>
        {popup && (
          <div className="popup">
            <p>Verification email sent. Please check your inbox.</p>
          </div>
        )}
        <div className="login-link">
          <p>
            Already have an account? <a href="/login">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Registration;
