import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Pages/Header";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Nopage from "./Pages/Nopage";
import BookSearch from "./category/BookSearch";
import Registration from "./Account/Registration";
import Login from "./Account/Login";
import ResetPassword from "./Account/Resetpassword";
import Admin from "./Account/Admin";
import ProtectedRoute from "./Pages/ProtectedRoute"; // Assuming you have a ProtectedRoute component
import PrivateRoute from "./Pages/PrivateRoute";
import "./App.css";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* PrivateRoute for admin section */}
        <Route
          path="/admin/*"
          element={
            <PrivateRoute adminOnly>
              <Admin />
            </PrivateRoute>
          }
        />

        {/* ProtectedRoute for BookSearch */}
        <Route
          path="/bookpage"
          element={
            <ProtectedRoute>
              <BookSearch />
            </ProtectedRoute>
          }
        />

        {/* ProtectedRoute for Contact */}
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />

        {/* 404 Page */}
        <Route path="*" element={<Nopage />} />
      </Routes>
    </Router>
  );
}

export default App;
