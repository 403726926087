import React, { useState, useEffect } from "react";
import "./Biology.css"; // Import the CSS file

const Environment = () => {
  const queries = ["ecology", "hygiene", "climate change", "toxicology"];
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllBooks();
  }, []);

  const fetchAllBooks = async () => {
    setLoading(true); // Set loading state to true when fetching starts

    try {
      const allBooks = [];
      for (const query of queries) {
        const response = await fetch(
          `https://gutendex.com/books?search=${query}&limit=50`
        );
        const data = await response.json();
        allBooks.push(...data.results);
      }
      setBooks(allBooks);
    } catch (error) {
      console.error("Error fetching books:", error);
    }

    setLoading(false); // Set loading state to false when fetching completes
  };

  return (
    <div className="biology-container">
      <h1>Books under Environmental Health</h1>

      {/* Display loading spinner */}
      {loading && <div className="spinner"></div>}

      <div className="books-grid">
        {books.length > 0 ? (
          books.map((book) => (
            <div key={book.id} className="book-card">
              {book.formats["image/jpeg"] && (
                <img
                  src={book.formats["image/jpeg"]}
                  alt={`${book.title} cover`}
                  className="book-image"
                />
              )}
              <div className="book-details">
                <h2>{book.title.split(":")[0] + "."}</h2>
                <p>by {book.authors.map((author) => author.name).join(", ")}</p>
                <div className="book-button">
                  {book.formats["text/html"] && (
                    <a
                      href={book.formats["text/html"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="book-link"
                    >
                      Read Live
                    </a>
                  )}
                  {book.formats["application/epub+zip"] && (
                    <a
                      href={book.formats["application/epub+zip"]}
                      download={`${book.title}.epub`}
                      className="book-link"
                    >
                      Download EPUB
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          !loading && <p>No books found.</p>
        )}
      </div>

      {/* Conditionally render the lastpage div */}
      {!loading && books.length > 0 && (
        <div className="lastpage">
          <p className="copy">Copyright 2024 WOCLIF School Of Health Technology. All rights reserved.</p>
        </div>
      )}
    </div>
  );
};

export default Environment;
