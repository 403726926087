import React, { useState } from "react";
import Biology from "../category/Biology"; // Importing the Biology component
import Physics from "../category/Physics"; // Importing the Physics component
import Physiology from "../category/Physiology"; // Importing the Physiology component
import Chemistry from "../category/Chemistry"; // Importing the Chemistry component
import "./Bookpage.css"; // Corrected import path for App.css
import Anatomy from "../category/Anatomy";
import Community from "../category/Community";
import Diseases from "../category/Diseases";
import Entrepreneurship from "../category/Entrepreneurship";
import Enviroment from "../category/Environmet";
import Epidemiology from "../category/Epidemiology";
import Journals from "../category/Journals";
import Mathematics from "../category/Mathematics";
import Psychology from "../category/Psychology";
import Public from "../category/Public";
import Research from "../category/Research";
import Statistics from "../category/Statistics";
import English from "../category/English";
import Computer from "../category/Computer";
import Citizenship from "../category/Citizenship";



function Bookpage() {
  const [selectedCategory, setSelectedCategory] = useState("biology"); // Default to an empty string

  // Function to handle changes in the dropdown menu
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value); // Updating the selected category state based on the user's choice
  };

  return (
    <div className="bookpage-container">
      <div className="dropdown-containers">
        <select
          name="category"
          id="category-select"
          onChange={handleCategoryChange} // Event handler for change event
          value={selectedCategory} // Set the selected value to the state value
          className="category-select"
        >
          {/* Placeholder option */}
          <option value="biology">Select a category</option>{" "}
          <option value="anatomy">Anatomy</option> {/* Option for Chemistry */}
          <option value="biology">Biology</option> {/* Option for Biology */}
          <option value="chemistry">Chemistry</option>{" "}
          <option value="citizenship">Citizenship Education</option>{" "}
          <option value="community health">Community Health</option>{" "}
          <option value="disease">Disease</option> {/* Option for Chemistry */}
          <option value="entrepreneurship">Entrepteneurship</option>{" "}
          <option value="environment">Environment</option>{" "}
          <option value="epidemiology">Epidemiology</option>{" "}
          <option value="computer">Introduction to Computer</option>{" "}
          <option value="journals">Journals</option> {/* Option for Biology */}
          <option value="mathematics">Mathematics</option>{" "}
          <option value="physics">Physics</option> {/* Option for Physics */}
          <option value="physiology">Physiology</option>{" "}
          <option value="psychology">Psychology</option>{" "}
          <option value="public">Public Health</option>{" "}
          <option value="research">Research Methodology</option>{" "}
          <option value="statistics">Statistics</option>{" "}
          <option value="english">Use of English</option>{" "}
        </select>
      </div>
      <div className="category-container">
        {/* Conditionally rendering the selected category component */}
        {selectedCategory === "anatomy" && <Anatomy />}{" "}
        {/* Display Biology component if biology is selected */}
        {selectedCategory === "biology" && <Biology />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "chemistry" && <Chemistry />}{" "}
        {/* Display Physics component if physics is selected */}
        {selectedCategory === "physics" && <Physics />}{" "}
        {/* Display Physiology component if physiology is selected */}
        {selectedCategory === "physiology" && <Physiology />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "community health" && <Community />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "disease" && <Diseases />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "entrepreneurship" && <Entrepreneurship />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "environment" && <Enviroment />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "epidemiology" && <Epidemiology />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "journals" && <Journals />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "mathematics" && <Mathematics />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "psychology" && <Psychology />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "public" && <Public />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "research" && <Research />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "statistics" && <Statistics />}{" "}
        {/* Display Chemistry component if chemistry is selected */}
        {selectedCategory === "english" && <English />}{" "}
        {selectedCategory === "computer" && <Computer />}{" "}
        {selectedCategory === "citizenship" && <Citizenship />}{" "}
      </div>
    </div>
  );
}

export default Bookpage; // Exporting the Bookpage component as the default export
