import React, { useState } from "react";
import { resetPassword, checkIfUserExists } from "../firebase";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./ResetPassword.css";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleResetPassword = async () => {
    setError(null);
    setResetSuccess(false);
    setShowError(false);

    if (!email) {
      setShowError(true);
      return;
    }

    try {
      setLoadingResetPassword(true);
      const isUserExists = await checkIfUserExists(email);

      if (!isUserExists) {
        await resetPassword(email);
        setResetSuccess(true);
      } else {
        setError("User with verified email does not exist.");
      }
    } catch (error) {
      setError("Enter your valid email to reset your password.");
    } finally {
      setLoadingResetPassword(false);
    }
  };

  return (
    <div className="reset-password">
      <div className="reset-container">
        <h2>Reset Password</h2>
        <div className="input-group">
          <div className="input-container">
            <FontAwesomeIcon icon={faEnvelope} />
            <input
              type="email"
              required
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={showError && !email ? "input-error" : ""}
            />
          </div>
          {showError && !email && (
            <p className="validation-error">Email is required.</p>
          )}
        </div>
        {error && <p className="error-message">{error}</p>}
        <button
          onClick={handleResetPassword}
          disabled={loadingResetPassword}
          className="reset-button"
        >
          {loadingResetPassword ? "Resetting Password..." : "Reset Password"}
        </button>
        {resetSuccess && (
          <p className="success-message">
            Password reset email sent. Check your inbox.
          </p>
        )}
        <div className="login-link">
          <p>
            Remember your password? <Link to="/login">Login here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
