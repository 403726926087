import React, { useState, useEffect } from "react";
import { generateInvitationCode } from "../firebase";
import { useNavigate } from "react-router-dom";
import "./Admin.css";

function Admin() {
  const [newCode, setNewCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Generate Code");
  const navigate = useNavigate();
  const isAdmin = true; // Replace this with your actual admin check logic

  useEffect(() => {
    if (!isAdmin) {
      navigate("/signup");
    }
  }, [isAdmin, navigate]);

  const handleGenerateCode = async () => {
    setLoading(true);
    try {
      const code = await generateInvitationCode();
      setNewCode(code);
      setError(null);
      setButtonText("Copy Code");
    } catch (error) {
      setError("Failed to generate code. Please try again.");
      console.error("Generate Code Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(newCode)
      .then(() => {
        setButtonText("Copied");
      })
      .catch((err) => {
        console.error("Failed to copy code:", err);
      });
  };

  return (
    <div className="admin">
      <div className="admin-container">
        <h2>Administrator</h2>
        <p> Generate Invitation Codes</p>
        <button
          onClick={handleGenerateCode}
          className="generate-button"
          disabled={loading}
        >
          {loading ? "Generating Code..." : "Generate Code"}
        </button>
        {newCode && (
          <div className="new-code">
            <p>New Invitation Code</p>
            <p>{newCode}</p>
            <button
              className="generate-button"
              onClick={handleCopyCode}
              disabled={!newCode || loading}
            >
              {buttonText}
            </button>
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

export default Admin;
