import React, { useState } from 'react';
import './ContactForm.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faBook, faPen } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import Notification from './Notification';


const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [question, setQuestion] = useState('');
  const [error, setError] = useState({});
  const [notification, setNotification] = useState('');


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newError = {};
    if (!email || !validateEmail(email)) {
      newError.email = true;
    }
    if (!name) {
      newError.name = true;
    }
    if (!subject) {
      newError.subject = true;
    }
    if (!question) {
      newError.question = true;
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    const templateParams = {
      from_name: name,
      email_address: email,
      subject: subject,
      message: question,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICEID,
        process.env.REACT_APP_EMAILJS_TEMPLATEID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USERID
      );
      setNotification('Your request has been sent successfully!');
      setEmail('');
      setName('');
      setSubject('');
      setQuestion('');
      setError({});
    } catch (error) {
      alert('Failed to send message. Please try again later.');
      console.error('Error:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'question':
        setQuestion(value);
        break;
      default:
        break;
    }
    setError((prevError) => ({ ...prevError, [name]: false }));
  };

  const closeNotification = () => {
    setNotification('');
  };


  return (
    <div id="fancy">
            {notification && <Notification message={notification} onClose={closeNotification} />}

      <div className="contactcon">
        <h2>Lets Know Your Subject</h2>
        <form onSubmit={handleSubmit}>
          <div className="left">
            <div className="name-input">
              <input
                placeholder="Name..."
                type="text"
                name="name"
                value={name}
                onChange={handleInputChange}
                className={error.name ? 'error' : ''}
              />
              <span className="icon">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </div>

            <div className="email-input">
              <input
                placeholder="Email address..."
                type="text"
                name="email"
                value={email}
                onChange={handleInputChange}
                className={error.email ? 'error' : ''}
              />
              <span className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>

            <div className="subject">
              <select
                name="subject"
                value={subject}
                onChange={handleInputChange}
                className={error.subject ? 'error' : ''}
              >
                <option value="">Choose request...</option>
                <option value="Enquiry">Enquiry</option>
                <option value="Complaint">Complaint</option>
                <option value="Suggestion">Suggestion</option>
              </select>
              <span className="icon">
                <FontAwesomeIcon icon={faBook} />
              </span>
            </div>
          </div>

          <div className="right">
            <div className="textarea-container">
              <textarea
                placeholder="Write here..."
                name="question"
                value={question}
                onChange={handleInputChange}
                className={error.question ? 'error' : ''}
              />
              <span className="icon">
                <FontAwesomeIcon icon={faPen} />
              </span>
            </div>
          </div>
        
        </form>
        <div className="btn-holder">
            <button onClick={handleSubmit} className="btns" type="submit">
              Send request
            </button>
          </div>
      </div>
    </div>
  );
};

export default ContactForm;
