import React from "react";
import "./About.css";
import img1 from "../Images/jpg2png/IMG-20240708-WA0014.png";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subscribe from "./Subscribe";


const About = () => {
  return (
    <div className="About">
      <div className="about-container">
        <div className="about-section">
          <h2>About Us</h2>
        </div>
      </div>
      <div className="about-details">
        <div className="aboutgallerycontainer">
          <h3>
            Why is WOCLIF School of Health Technology the Ideal Study Center for
            Future Public Health Leaders?
          </h3>
          <div className="para-about">
            <p>
              WOCLIF School of Health Technology is a private health training
              institution established and registered in 2019 by Women &
              Community Livelihood Foundation (WOCLIF) for the training of
              middle health manpower. It is located at Mbioto 1 community,
              Etinan LGA, Akwa Ibom State, Nigeria. It is the first private
              school of health technology in Akwa Ibom State.
            </p>
            <p>
              The idea for the establishment of the school came about when
              WOCLIF as an organization was working on HIV/AIDS, TB, Malaria,
              Care and Support for Orphans and Vulnerable Children in the rural
              communities of Akwa Ibom State using Community Volunteers since
              2009.
            </p>

            <p>
              WOCLIF School of Health Technology is therefore set to prepare the
              next generation of Public health leaders and constantly address
              the world's most pressing public health issues.
            </p>
          </div>
          <div className="image-gallery">
            <img src={img1} alt="" />
          </div>
        </div>

        <p className="paddingtoppara">
          Here, you'll do more than learn facts – you'll join in groundbreaking
          research, team up with peers, and learn from top experts in public
          health. You'll also get hands-on experiences outside the classroom
          through internships, extracurricular activities, workshops, and
          real-world projects, turning theory into practice which will propel
          you to the forefront of sought-after opportunities in the job market.
        </p>
        <p>
          So, are you ready to embark on a journey that goes beyond textbooks
          and classrooms? Are you ready to shape communities, influence
          policies, and make a lasting impact? Join us at WOCLIF SCHOOL OF
          HEALTH TECHNOLOGY, where we're not just redefining education; we're
          redefining wellness, one future Public Health Leader at a time.
        </p>
      </div>
      <div className="footerpart">
        <footer id="footer">
          <div className="container">
            <section>
              <article className="col-1">
                <h3>Contact</h3>
                <ul>
                  <li className="address">
                    <a href="#">
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> Mbiotic 1,
                      Etinan L.g.a., Akwa Ibom State
                    </a>
                  </li>
                  <li className="mail">
                    <a
                      href="mailto: woclifhealthtech@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                      woclifhealthtech@gmail.com
                    </a>
                  </li>
                  <li className="phone last">
                    <a
                      href="tel: 08036716825"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faPhone} /> (234) 803 671 6825
                    </a>
                  </li>
                </ul>
              </article>
              <article className="col-2">
                <h3>National Diploma </h3>
                <ul>
                  <li>
                    <a href="#">Public Health Technology</a>
                  </li>
                  <li>
                    <a href="#">Epidemiology & Disease Control</a>
                  </li>
                  <li>
                    <a href="#">Environmental Health Technology </a>
                  </li>
                  <li>
                    <a href="#">Community Health Extention Work </a>
                  </li>

                  <li>
                    <a href="#">Paramedics & Medical Social Work</a>
                  </li>
                </ul>
              </article>

              <article className="col-3">
                <h3>Social media</h3>
                <span>
                  Connect with us on social media and stay updated with the
                  latest news and events.
                </span>
                <ul>
                  <li
                    className="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a href="https://www.facebook.com/woclif.schoolofhealthtech">
                      <FontAwesomeIcon icon={faFacebook} /> Facebook
                    </a>
                  </li>
                  <li className="google-plus">
                    <a
                      href="https://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                      Instagram
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} /> Twitter(X)
                    </a>
                  </li>
                  <li
                    className="pinterest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a
                      href="https://www.tiktok.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} size="2x" />
                      Tiktok
                    </a>
                  </li>
                </ul>
              </article>
              <article className="col-4">
                <h3>Newsletter</h3>
                <span>
                  Sign up for our newsletter to get school updates and news
                </span>
                <Subscribe />
              </article>
            </section>
            <p className="copy">
              Copyright 2024 WOCLIF School Of Health Technology. All rights
              reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default About;
