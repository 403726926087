import React from 'react'
import "./Contact.css";
import ContactForm from './ContactForm';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subscribe from './Subscribe';


function Contact() {


  return (
    <div className="contact">
      <div className="contactbg">
        <div className="contacttext">
          <h2>we are eager to learn from you. welcome to WOCLIF!</h2>
        </div>
      </div>
      <div className="contactform">
        <h2>Contact Form</h2>
        <ContactForm />
      </div>

      <div className="footerpart">
        <footer id="footer">
          <div className="container">
            <section>
              <article className="col-1">
                <h3>Contact</h3>
                <ul>
                  <li className="address">
                    <a href="#">
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> Mbiotic 1,
                      Etinan L.g.a., Akwa Ibom State
                    </a>
                  </li>
                  <li className="mail">
                    <a
                      href="mailto: woclifhealthtech@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                      woclifhealthtech@gmail.com
                    </a>
                  </li>
                  <li className="phone last">
                    <a
                      href="tel: 08036716825"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faPhone} /> (234) 803 671 6825
                    </a>
                  </li>
                </ul>
              </article>
              <article className="col-2">
                <h3>National Diploma </h3>
                <ul>
                  <li>
                    <a href="#">Public Health Technology</a>
                  </li>
                  <li>
                    <a href="#">Epidemiology & Disease Control</a>
                  </li>
                  <li>
                    <a href="#">Environmental Health Technology </a>
                  </li>
                  <li>
                    <a href="#">Community Health Extention Work </a>
                  </li>

                  <li>
                    <a href="#">Paramedics & Medical Social Work</a>
                  </li>
                </ul>
              </article>

              <article className="col-3">
                <h3>Social media</h3>
                <span>
                  Connect with us on social media and stay updated with the
                  latest news and events.
                </span>
                <ul>
                  <li
                    className="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a href="https://www.facebook.com/woclif.schoolofhealthtech">
                      <FontAwesomeIcon icon={faFacebook} /> Facebook
                    </a>
                  </li>
                  <li className="google-plus">
                    <a
                      href="https://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                      Instagram
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} /> Twitter(X)
                    </a>
                  </li>
                  <li
                    className="pinterest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a
                      href="https://www.tiktok.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} size="2x" />
                      Tiktok
                    </a>
                  </li>
                </ul>
              </article>
              <article className="col-4">
                <h3>Newsletter</h3>
                <span>
                  Sign up for our newsletter to get school updates and news
                </span>
                <div>
                  <Subscribe />
                </div>
              </article>
            </section>
            <p className="copy">
              Copyright 2024 WOCLIF School Of Health Technology. All rights
              reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Contact
