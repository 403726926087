import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./GoogleFormToggle.css";
import form from '../Images/form.png'

const GoogleFormToggle = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setIsTimeout(false); // reset timeout status when toggled
  };

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setIsOpen(false);
        setIsTimeout(true); // indicate that timeout closed the form
      }, 20000); // 10 seconds
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <div className="google-form-toggle">
      <div className="googleformicon">
        <img onClick={handleToggle} src={form} alt="form" />
      </div>
      {isOpen && (
        <div className="dropdown-container">
          <FontAwesomeIcon
            icon={faTimes}
            className="cancel-icon"
            onClick={handleToggle}
          />
          <h3>2024/2025 APPLICATION FORM IS OUT</h3>
          <h4>INSTRUCTION:</h4>

          <p>
            An email verification link will be sent to your G-mail, click on
            Verify Your Email Address to verify your email.
          </p>
          <p>
            Fill the application form with your Surname, Firstname, Middlename,
            Email Address (only G-mail is allowed), and Phone Number.
          </p>
          <p>
            <p>Click here to apply for 2024/2025 application form.</p>
          </p>
          <button
            className="apply-button"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSfkIN40_FJ13qqHVNK0Zkdz7KebspMTLKNxejOSrfm0norixw/viewform?usp=pp_url"
              )
            }
          >
            Apply Now
          </button>
        </div>
      )}
    </div>
  );
};

export default GoogleFormToggle;
